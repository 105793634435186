import { Product } from "../../app/models/product";
import ProductList from "./ProductList";
import { useEffect, useState } from "react";

export default function Catalog() {
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    fetch("https://localhost:44337/api/product")
      .then((resp) => resp.json())
      .then((data) => setProducts(data));
  }, []);

  return (
    <>
      <ProductList products={products}></ProductList>
    </>
  );
}
